<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Diário Atendimento
        </h1>
      </v-col>
    </v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <h4>Turma: {{ gestao ? gestao.turma.descricao : "- - -" }}</h4>
          <h4>Aluno: {{ gestao ? gestao.matricula.aluno.nomecompleto : "- - -" }}</h4>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title>Professores (diários)</v-card-title>
        <v-card-text>
          <div v-for="diario in gestao_de_aulas" :key="diario.id">
            Professor(a): {{ diario.professor.nome }} | Disciplina:
            {{ diario.disciplina ? diario.disciplina.descricao : "" }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="data" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <e-label>Selecione uma data</e-label>
              <v-text-field
                v-model="aulaSelecionada.data"
                persistent-hint
                label="Escolha uma data"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="data" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <!-- <v-col class="pt-0 pb-0" :cols="12" :sm="4">
          <ValidationProvider name="Data" rules="required">
            <e-label>Data </e-label>
            <e-date-picker v-model="form.data" />
          </ValidationProvider>
        </v-col> -->
        <v-col class="pt-0 pb-0" cols="6">
          <ValidationProvider name="Horário da aula" rules="required">
            <e-label>Selecione um horário</e-label>
            <e-autocomplete
              :items="horarios"
              label="Selecione uma opção"
              :item-text="(item) => item.descricao"
              v-model="form.horario_aee_id"
              :item-value="(item) => item.id"
              dense
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-col>

    <v-col>
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialDiario" :key="index">
          <v-col cols="12">
            <e-label>{{ item.descricao }}</e-label>
            <v-textarea solo dense v-model="form[item.form]" />
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              @click="createOrUpdate"
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </v-col>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      form: {
        data: null,
        horario_aee_id: {
          descricao: "Escolha um horario",
        },
      },
      ficha: null,
      gestao: null,
      modal: false,
      menu: false,
      horarios: [],
      matricula: null,
      gestao_de_aulas: {},
      submittingForm: false,
      aulaSelecionada: {},
      data: null,
    };
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    async createOrUpdate() {
      this.submittingForm = true;
      // this.$loaderService.open("Carregando dados...");
      try {
        this.form.data = this.data;
        const { gestao_id } = this.$route.params;
        this.form.gestao_id = gestao_id;
        this.$toast.info("Diário de atendimento criado!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimento(this.form);
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "educacaoInclusiva.fichaDiarioAtendimento" });

      this.submittingForm = false;
    },

    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const { gestao_id } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimento(gestao_id);
        this.matricula = response.matricula;
        this.gestao = response.gestao;
        this.horarios = response.horarios.filter(
          (horario) => horario.turno_id === this.gestao.turma.turno_id
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
