var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-template',[_c('v-row',[_c('v-col',[_c('h1',[_c('v-btn',{attrs:{"color":"primary","x-small":"","text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-angle-left ")])],1),_vm._v(" Diário Online ")],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mb-2 justify-space-between"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])),expression:"$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"}],staticClass:"mr-2",attrs:{"color":"success"},on:{"click":_vm.criarGestaoDeAula}},[_vm._v("Criar Vínculo")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{staticClass:"pt-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.gestoesDeAulasList,"loading":_vm.carregandoGestoesDeAulas,"items-per-page":10,"hide-default-footer":"","no-data-text":"Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c("transition-group",{tag:"tbody",attrs:{"name":"scroll-x-transition","duration":"150"}},_vm._l((items),function(gestaoDeAulas){return _c('tr',{key:gestaoDeAulas.id,class:gestaoDeAulas.estaDeletado
                  ? ['deleted']
                  : parseInt(gestaoDeAulas.notifiq, 10) === 1
                  ? 'green lighten-5'
                  : '',attrs:{"color":"primary"}},[_c('td',[_vm._v(_vm._s(gestaoDeAulas.id))]),_c('td',{attrs:{"width":"15%"}},[_vm._v(" "+_vm._s(gestaoDeAulas.nomeFranquia ? gestaoDeAulas.nomeFranquia : "- - -")+" ")]),_c('td',{attrs:{"width":"18%"}},[_vm._v(" "+_vm._s(gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -")+" "),(parseInt(gestaoDeAulas.notifiq, 10) === 1)?_c('v-chip',{staticClass:"white--text",attrs:{"label":"","color":"green darken-4","x-small":""}},[_vm._v(" NOTIFIQ ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(gestaoDeAulas.nomeDisciplina ? gestaoDeAulas.nomeDisciplina : "- - -"))]),_c('td',{attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(gestaoDeAulas.nomeProfessor ? gestaoDeAulas.nomeProfessor : "- - -")+" ")]),_c('td',[_c('e-resumo-aulas-snippet',{attrs:{"aulas":gestaoDeAulas.aulas}})],1),_c('td',{attrs:{"width":"7%"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"blue","dark":""}},'v-btn',attrs,false),on),[_vm._v(" AÇÕES ")])]}}],null,true)},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"300"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(gestaoDeAulas.disciplina.descricao ? gestaoDeAulas.disciplina.descricao.substring(0, 1).toUpperCase() : "- - -"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(gestaoDeAulas.nomeProfessor ? gestaoDeAulas.nomeProfessor : "- - -"))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(gestaoDeAulas.nomeTurma ? gestaoDeAulas.nomeTurma : "- - -")+" / "+_vm._s(gestaoDeAulas.nomeDisciplina ? gestaoDeAulas.nomeDisciplina : "- - -")+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',[(
                            !gestaoDeAulas.estaDeletado &&
                            gestaoDeAulas.turma.curso_id !== 10 &&
                            gestaoDeAulas.tipo_de_atendimento !==
                              'Atendimento educacional especializado (AEE)'
                          )?_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":{
                              name: 'notas.circuitoNotas',
                              params: {
                                gestaoDeAulasId: gestaoDeAulas.id,
                                disciplinaId: gestaoDeAulas.disciplina_id,
                              },
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-file-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Notas")])],1)],1)],1):_vm._e(),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":() => _vm.listarAulas(gestaoDeAulas)}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Confirmações de aulas")])],1)],1)],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[(
                              !gestaoDeAulas.estaDeletado &&
                              gestaoDeAulas.turma.curso_id === 10 &&
                              gestaoDeAulas.tipo_de_atendimento !==
                                'Atendimento educacional especializado (AEE)'
                            )?_c('v-list-item',{attrs:{"to":{
                              name: 'relatorios.visualizar',
                              params: {
                                gestaoDeAulasId: gestaoDeAulas.id,
                                turmaId: gestaoDeAulas.turma_id,
                              },
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-file-invoice")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(gestaoDeAulas.turma.curso.descricao))])],1)],1):_vm._e()],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[(
                              gestaoDeAulas.tipo_de_atendimento ===
                              'Atendimento educacional especializado (AEE)'
                            )?_c('v-list-item',{attrs:{"to":{
                              name: 'turmas.view',
                              params: {
                                turma_id: gestaoDeAulas.turma_id,
                                gestaoDeAulasId: gestaoDeAulas.id,
                              },
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-file-invoice")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Educação Especial")])],1)],1):_vm._e()],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":() => _vm.verAulas(gestaoDeAulas)}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aulas")])],1)],1)],1),(
                            !gestaoDeAulas.estaDeletado &&
                            _vm.$can(['gestoesDeAulas.atualizar', 'gestoesDeAulas.*'])
                          )?_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":() => _vm.editarGestaoDeAula(gestaoDeAulas)}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar Gestão de aulas")])],1)],1)],1):_vm._e(),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":{
                              name: 'gestoesDeAulas.planoBimestralCreate',
                              params: { gestaoDeAulaId: gestaoDeAulas.id },
                            }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-book")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Plano bimestral")])],1)],1)],1),_c('v-list-item',{attrs:{"to":{
                            name: 'gestoesDeAulas.metricasTurma',
                            params: {
                              id: gestaoDeAulas.turma.id,
                              gestao: gestaoDeAulas.id,
                            },
                          }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-box")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Métricas da turma")])],1)],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":function($event){return _vm.capaDiarioClasse(gestaoDeAulas.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-book-open")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Capa de diário de classe")])],1)],1)],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-menu',{attrs:{"open-on-hover":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{
                                  name: 'gestoesDeAulas.planoBimestralCreate',
                                  params: { gestaoDeAulaId: gestaoDeAulas.id },
                                }}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-file-pdf")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Gerar plano bimestral ")])],1)],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.$constants.bimestres),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{on:{"click":function($event){return _vm.gerarPlanoBimestral(gestaoDeAulas.id, item)}}},[_vm._v(" "+_vm._s(item.desc)+" ")])],1)}),1)],1)],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":() => _vm.recuperarGestaoDeAula(gestaoDeAulas)}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-sync")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Recuperar Gestão de aulas ")])],1)],1)],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":() => _vm.deletarGestaoDeAula(gestaoDeAulas)}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-trash")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Apagar Gestão de aulas ")])],1)],1)],1)],1)],1)],1)],1)])])}),0)]}}])}),(_vm.dialogConfirmarAulas)?_c('div',[_c('e-dialog-search-aulas-interval-date',{attrs:{"gestao_selected_to_interval":_vm.gestao_selected_to_interval,"dialogConfirmarAulas":_vm.dialogConfirmarAulas},on:{"dialogAulasChange":function($event){_vm.dialogConfirmarAulas = $event},"carregarGestoesNovamenteEmit":_vm.carregarGestoesNovamenteAposConfirmarAulaPeloModal}})],1):_vm._e(),_c('e-paginate',{attrs:{"current_page":_vm.paginate.current_page,"last_page":_vm.paginate.last_page,"pageNumber":_vm.pageNumber,"search":_vm.search},on:{"changeActionResponse":_vm.listarGestoesDeAulas}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }